import * as React from 'react';
import { PureComponent } from 'react';
import styled from 'styled-components';
import { WhiteOwl } from '../atoms/images/WhiteOwl';
import { AppColor } from '../../constants/Color';
import { SiteTitle } from '../atoms/headings/SiteTitle';
import { HeaderVerticalSeparator } from '../atoms/separators/HeaderVerticalSeparator';

const StyledHeader = styled.header<{}>`
  background-color: ${AppColor.PRIMARY};

  margin-bottom: 1.45rem;
`;

const OwlContainer = styled.div`
  width: 3em;
  height: 3em;
`

const HomeContainer = styled.div`
  box-sizing: border-box;

  margin: 0 auto;
  max-width: 960px;
  padding: 1.45rem 1.0875rem;
  padding-bottom: 0.75rem;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
`;

const Link = styled.a`
  text-decoration: none;
`;

const LinkContainer = styled.div`
  margin: 0 auto;
  height: 2rem;
  max-width: 960px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
`;

const LinkItem = styled.a<{}>`
  box-sizing: border-box;

  background-color: transparent;
  transition-duration: 0.3s;
  
  padding-left: 1.45rem;
  padding-right: 1.45rem;

  display: flex;
  justify-content: center;
  align-items: center;
  
  text-decoration: none;

  &:hover{
    background-color: ${AppColor.TERTIARY};
    transition-duration: 0.3s;
  }
`;

const LinkText = styled.span`
  font-weight: 400;
  color: white;
`;

export class Header extends PureComponent{
  
  public render(): JSX.Element{
    return(
      <StyledHeader>
        <HomeContainer>
          <Link href="/">
            <OwlContainer>
              <WhiteOwl />
            </OwlContainer>
          </Link>
          <Link href="/">
            <SiteTitle />
          </Link>
        </HomeContainer>
        <LinkContainer>
          <LinkItem href="/articles">
            <LinkText>Articles</LinkText>
          </LinkItem>
          <HeaderVerticalSeparator />
          <LinkItem href="/documents">
            <LinkText>Documents</LinkText>
          </LinkItem>
          <HeaderVerticalSeparator />
          <LinkItem href="/members">
            <LinkText>Members</LinkText>
          </LinkItem>
        </LinkContainer>
      </StyledHeader>
    )
  }
}