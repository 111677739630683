import * as React from 'react';
import { PureComponent } from 'react';
import styled from 'styled-components';

const Line = styled.span`
  display: inline-block;

  width: 1px;
  height: 1em;

  background-color: white;
`;

const LineContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export class HeaderVerticalSeparator extends PureComponent{
  public render(): JSX.Element{
    return(
      <LineContainer>
        <Line />
      </LineContainer>
    );
  }
}