import * as React from 'react';
import { PureComponent } from "react";
import styled from 'styled-components';

const image = require("../../../images/manic-owl-white.svg");

const StyledImg = styled.img`
  width: 100%;
  height: 100%;

  object-fit: contain;
`;

export class WhiteOwl extends PureComponent{

  public render() : JSX.Element{
    return(
      <StyledImg
        alt="manicreator.com"
        src={image}
      />
    );
  }
}