export const AppColor = {
  WHITE: '#ffffff',
  PRIMARY: '#bf0f58',
  SECONDARY: '#736d71',
  SECONDARY_TRANS: '#736d7177',
  TERTIARY: '#000028',
  PRIMARY_TRANS: '#bf0f5822',
  DISABLED: '#00000044',
  QUOTE_BACKGROUND: '#736d7122',
}

export const FontColor = {
  SECONDARY: '#ffffffdd',
}

export const BlandColor ={
  YOUTUBE: '#ff0000',
  TWITTER: '#1da1f2',
  FACEBOOK: '#4267B2',
}