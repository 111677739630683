import * as React from 'react';
import { PureComponent } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby";

const H1 = styled.h1<{}>`
  font-weight: 300;
  margin-bottom: 0px;

  color: white;
`;

export class SiteTitle extends PureComponent{

  public render() : JSX.Element{
    const { site } = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `
    );

    return(
      <H1>
        {site.siteMetadata.title}
      </H1>
    );
  }
}