import * as React from 'react';
import { PureComponent } from 'react';
import styled from 'styled-components';
import { AppColor, FontColor } from '../../constants/Color';
import { useStaticQuery, graphql } from 'gatsby';

const Foot = styled.footer<{}>`
  box-sizing: border-box;

  background: ${AppColor.SECONDARY};

  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  
  text-align: center;
  font-size: 0.8rem;
  color: ${FontColor.SECONDARY};
`;

export class Footer extends PureComponent{

  public render() : JSX.Element{
    const {site} = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              author
              since
            }
          }
        }
      `
    );

    const metaData = site.siteMetadata;

    const year: number = new Date().getFullYear();
    const yearStr: string = year > metaData.since ?
      `${metaData.since}-${year}` :
      `${metaData.since}`

    return(
      <Foot>
        © {yearStr} {metaData.author}
      </Foot>
    )
  }
}