/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */



import * as React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby"
import { Component } from "react";

interface Property{
  title?: string;
  description?: string;
  cover?: string;
  isArticle?: boolean;
}

export class SEO extends Component<Property>{
  
  public render() : JSX.Element{
    const { site } = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              title
              description
              author
              home
            }
          }
        }
      `
    );

    const metaDescription = this.props.description || site.siteMetadata.description;
    const title = this.props.title || site.siteMetadata.title;
    const actualTitle = `${this.props.title} | ${site.siteMetadata.title}` || site.siteMetadata.title;
    const titleTemplate = site.siteMetadata.title !== undefined ?  `%s | ${site.siteMetadata.title}` : site.siteMetadata.title;
    const coverImage = `${site.siteMetadata.home}${this.props.cover || `/images/manic-owl.png`}`;
    const isArticle = this.props.isArticle || false;

    return (
      <Helmet
        htmlAttributes={{
          lang: 'ja',
          prefix: "og: http://ogp.me/ns#",
        }}
        title={title}
        titleTemplate={titleTemplate}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: actualTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:image`,
            content: coverImage,
          },
          {
            property: `og:type`,
            content: isArticle ? `article` : `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: actualTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat([])}
      />
    )
  }
}